import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../../../components/layout';
import { Seo } from '../../../components/seo';
import * as Styles from '../../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../../styles/global/_common.module.scss';
import { BackGround } from '../../../components/background';
import { Link, useIntl } from "gatsby-plugin-react-intl"
import queryString from "query-string";
import {passwordRegist} from "../../../services/auth";
import {navigate} from "gatsby-plugin-react-intl";
import {postAPI} from "../../../services/fetch";
import * as LoadingElementStyles from "../../../styles/components/_loading_element.module.scss";
import LoadingElement from "../../../components/LoadingElement";

const PasswordResetPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [isLoading, setIsLoading] = React.useState(false)
  const [password, setPassword] = React.useState({ latest: '', confirm: '' })
  const [errorPassword, setErrorPassword] = React.useState({ isError: false, message: '' });

  const onClickChangePassword = async () => {
    if (password.latest === '' || password.confirm === '') {
      setErrorPassword({isError: true, message: t('users.register.error.pleasePassword')})
      return
    }
    if (password.latest !== password.confirm) {
      setErrorPassword({isError: true, message: t('users.register.error.differencePassword')})
      return
    }
    setErrorPassword({isError: false, message: ''})
    setIsLoading(true)

    const { p, email } = queryString.parse(location.search)
    // console.log('########', p, email)
    try {
      const response = await postAPI({ url: '/api/user/password/reset', params: {
        email,
        token: p,
        password: password.latest,
        password_confirmation: password.confirm
      }})
      if (response.data.result !== 0) {
        setErrorPassword({isError: true, message: t('users.register.error.invalidToken')})
        await navigate('/users/invalid')
        return
      }
      await navigate('/users/password/complete')
      setIsLoading(false)
    } catch (e) {
      setErrorPassword({isError: true, message: 'バリデーションエラー'})
      setIsLoading(false)
    }
  }

  return (
    <Layout location={location}>
      <div className={isLoading ? '' : LoadingElementStyles.hidden}>
        <LoadingElement />
      </div>
      <Seo title={t('users.password.change.seo.title')} description={t('users.password.change.seo.description')} />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>{t('users.password.change.title')}</h1>
              <section>
                <div className={Styles.mg_b2}>
                  <div>
                    <label htmlFor="user_password">{t('users.password.change.newPassword')}</label>
                    <input
                      id="user_password"
                      value={password.latest}
                      onChange={(e) => setPassword({...password, latest: e.target.value})}
                      autoFocus="autofocus"
                      type="password"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label htmlFor="user_password_confirmation">{t('users.password.change.confirmPassword')}</label>
                    <input
                      id="user_password_confirmation"
                      value={password.confirm}
                      onChange={(e) => setPassword({...password, confirm: e.target.value})}
                      autoFocus="autofocus"
                      type="password"
                      autoComplete="off"
                    />
                  </div>
                  <div className={`${Styles.error_message} ${(errorPassword.isError) && Styles.is_error}`}>{errorPassword.message}</div>
                  <div className={Styles.text_center}>
                    <button
                      type="button"
                      className={Styles.btn_primary}
                      onClick={onClickChangePassword}
                    >
                      {t('users.password.change.changePassword')}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default PasswordResetPage;
